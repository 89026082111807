import React from 'react';
import { Row, Col, Form, Input, Button, Card, Typography, message } from 'antd';
import { HeartFilled, CheckCircleOutlined } from '@ant-design/icons';
import sideImage from './../../assets/images/section-3.svg';
import superagent from 'superagent';
import { observer, inject } from 'mobx-react';

const toastKey = 'str';
@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => {
      return {
        loading: false,
      };
    };

    this.state = this.initialState();

    this.onFinish = (values) => {
      this.setState({ loading: true });
      superagent
        .post(`${process.env.REACT_APP_API_LINK}/auth/login`)
        .send({ username: values.username, password: values.password }) // sends a JSON post body
        .set('Content-Type', 'application/json')
        .type('json')
        .end((err, res) => {
          this.setState({ loading: false });
          if (err) {
            console.log("🚀 ~ file: index.js ~ line 32 ~ Login ~ .end ~ err", err)
            message.error({ content: 'Login Failed!', key: toastKey });
          } else {
            const { body } = res;
            if (body.success) {
              this.props.userStore.value = body.data.user;
              this.props.sectionsStore.value = body.data.sections;
              this.props.tokenStore.value = body.data.token;
            } else {
              message.error({ content: body.msg, key: toastKey });
            }
          }
        });
    };
  }

  onFinishFailed(feedback) {}
  render() {
    if (`${this.props.tokenStore.value}`.trim() != '') {
      return this.props.to;
    }
    return (
      <Row
        style={{ position: 'absolute', height: '100%', width: '100%' }}
        type="flex"
        align="middle"
      >
        <Col
          style={{
            height: '100%',
            // backgroundColor: 'rgb(251, 251, 251)',
            backgroundImage: `url('${sideImage}')`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            boxShadow: '1px 3px 9px #dde4e7',
          }}
          span={15}
        >
          <Row
            style={{ position: 'absolute', height: '100%', width: '100%' }}
            type="flex"
            align="middle"
          >
            <Col span={24}>
              {/* <img
                src={sideImage}
                style={{
                  width: '90%',
                  margin: '0 auto',
                  left: 0,
                  right: 0,
                  // top: '25%',
                }}
              /> */}
            </Col>
            <Col span={24} style={{ marginTop: '50%' }}>
              <Typography.Paragraph
                align="center"
                type="secondary"
                style={{ color: 'white' }}
              >
                <span>Developed with </span>
                <HeartFilled style={{ color: 'red' }} /> By&nbsp;
                <a href="#" title="Lucid Source">
                  Lucid Source
                </a>
              </Typography.Paragraph>
            </Col>
          </Row>
        </Col>
        <Col span={7} offset={1}>
          <Row>
            <Col span={24}>
              <Typography.Title align="center" color="primary">
                Box Media
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Card bordered={false}>
                <Form
                  name="basic"
                  initialValues={{}}
                  layout="vertical"
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        min: 3,
                        message: 'Minimum length for username is 3 characters!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Login;
