import { lazy } from 'react';
const options = [
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../home/Home')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/home',
    component: lazy(() => import('../home/Home')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/teammember',
    component: lazy(() => import('../teammember/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/client',
    component: lazy(() => import('../client/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/work',
    component: lazy(() => import('../work/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/worktype',
    component: lazy(() => import('../workType/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/user',
    component: lazy(() => import('../user/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/rentalitem',
    component: lazy(() => import('../rentalitem/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/capabilities',
    component: lazy(() => import('../capabilities/Capabilities.js')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/seo',
    component: lazy(() => import('../meta/SEO')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/about',
    component: lazy(() => import('../about/About')),
    exact: true,
  },
];
export default options;
